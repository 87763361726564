import React from "react";

function SliderContent({ activeIndex, sliderImage }) {
  return (
    <section>
      {sliderImage.map((slide, index) => (
        <div
          key={index}
          className={
            index === activeIndex
              ? "h-[50vh] w-full relative  inline-block"
              : "hidden"
          }
        >
          <div className="flex flex-col justify-center items-center gap-16">
            <div className="w-[200px] h-[200px] rounded-[100px] showdow-md">
              <img
                className="rounded-[100px] w-full h-full shadow-lg shadow-gray-500"
                src={slide.image}
                alt=" figure 1"
              />
            </div>
            <div className="w-[600px] text-center">
              {/* <p className="text-[1.2rem] italic text-gray-500">{slide.text}</p> */}
              <h1 className="text-[1rem] italic font-semibold">{slide.name}</h1>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
}

export default SliderContent;
