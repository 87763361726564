import React from "react";
// import {
//   FaFacebookF,
//   FaTwitter,
//   FaInstagramSquare,
//   FaLinkedin,
//   FaPinterest,
// } from "react-icons/fa";
import {BsTelephone} from 'react-icons/bs'
import {AiOutlineMail} from 'react-icons/ai'


const Footer = () => {
  return (
    <div className="w-full  justify-between h-[150px] items-center p-10 flex flex-col md:justify-center md:items-center md:p-10 md:h-[150px] md:justify-between">
      <ul className="text-start md:flex md:flex-row flex flex-col justify-center items-start  md:gap-8 text-gray-500">
        {/* <li className="text-[20px] text-blue-500">Contact Info</li> */}
        <li className="flex gap-4 justify-center items-center">
          <BsTelephone className="text-blue-700 " />
          +256 782 322 092/ +256 703 824 236
        </li>
        <li className="flex gap-4 justify-center items-center">
          <AiOutlineMail className="text-blue-700 " />
          arthur@visibleoutdoor.co.ug / visibleoutdoor@gmail.com
        </li>
        <li className="flex gap-4 justify-center items-center">
  
          P.O.BOX 35282 KAMPALA
        </li>
      </ul>
      <div className="m-2">
        <h4 className="text-gray-500">Copyright ©2022 All rights reserved</h4>
      </div>
    </div>
  );
};

export default Footer;
