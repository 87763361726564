import React, { useState } from "react";
// import { GrCycle } from "react-icons/gr";
import { motion } from "framer-motion";
// import entebbe from "../images/entebbe.png";

const Card = ({ title, description, image }) => {
  const [isHover, setIsHover] = useState(false);

  function sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  return (
    <motion.div
      initial={{ y: "15vw" }}
      whileInView={{ y: 0 }}
      viewport={{ once: false }}
      transition={{ type: "spring", duration: 2, bounce: 0.1 }}
      onMouseEnter={() => {
        sleep(100);
        setIsHover(true);
      }}
      onMouseLeave={() => {
        sleep(100);
        setIsHover(false);
      }}
      className="w-[350px] h-[300px] transition-500  rounded-lg flex  items-center flex-col hover:text-white"
    >
      {/* <div
        className={
          !isHover
            ? "bg-blue-500 rounded-[100px] p-[20px] absolute w-[80px] h-[80px] flex justify-center items-center z-20"
            : " hover:bg-white rounded-[100px] p-[20px] absolute w-[80px] h-[80px] flex justify-center items-center z-20"
        }
      >
        <GrCycle className="text-white text-[60px]" />
      </div> */}
      <div
        className={
          !isHover
            ? "flex flex-col  justify-center items-center flex-wrap  text-center bg-[#f1f1f1]  p-[2px] mt-[45px] rounded-lg z-2"
            : "flex flex-col  justify-center items-center flex-wrap  text-center  p-[2px] mt-[45px] rounded-lg"
        }
      >
        <img src={image} className="w-[340px] object-cover h-[250px]" alt="entebbe" />

        {isHover && (
          <motion.div
            // initial={{ y: "5vw" }}
            // whileInView={{ y: 0 }}
            // viewport={{ once: false }}
            animate={{ scale: 1.05 }}
            transition={{ type: "spring", duration: 2, bounce: 0.1 }}
            className="fixed flex-wrap text-center p-2"
          >
            <h4 className={"text-[19px] text-black font-bold"}>{title}</h4>
            <p className={"text-white font-semibold"}>{description}</p>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default Card;
