import React from "react";
import Card from "./Card";
import kamwokya from '../images/kamwokya.png'
import entebbe from '../images/entebbe.png'
import katwe from '../images/Katwe-entebbe.png'
import kansanga from '../images/kasanga.png'
import makindye from '../images/makindye.png'
import mbarara from '../images/mbarara.png'
import najerra from '../images/Najjerra-one.png'
import kalerwe from '../images/kalerwe.png'

const Features = () => {
  const fetures = [
    {
      title: "Kamwokya Kira road LED Screen",
      image:kamwokya,
      description:
        "Traffic from Kampala,  Accacia Avenue, Accacia mall, Bukoto street, kanjokya street, kira road users, Wandegeya, Makerere, Mulago, Jinja Road etc.  15 sec. add 100 runs a day",
    },
    {
      title: "Wall Branding",
      image:entebbe,
      description:
        "Portrait Billboard:2 faces available, ROAD:       Entebbe Road ,Target:Entebbe road traffic from and to kampala,Kibuye, Najjanankumbi, Namasumba, Zzana, Lubowa, Lweza, Kajjasi, Bwebaja, Abaitaababil, Entebbe Airport etc ",
    },
    {
      title: "Katwe Entebbe road billboard 10x12m",
      image:katwe,
      description:
        "Portrait Billboard:1 face available, ROAD: Katwe Entebbe Road, Entebbe road  traffic from kampala to Najjanankumbi, Makyindye, Munyonyo, Salama road, Namasumba, Zzana, Lubowa, Lweza, Kajjasi, Bwebaja, Abaitaababil, Entebbe Airport etc  ",
    },
    {
      title: "Kansanga Wall Sign 15x6m next to wonder world",
      image:kansanga,
      description:
        "1 face available.Targeting traffic from Munyonyo, Ggaba, Bunga, Buziga,  Kansanga community,  etc.",
    },
    {
      title: "Makyindye traffic Lights billboard 8x10m junction to the barracks",
      image:makindye,
      description:
        "2 faces available, Target: Entebbe road users,  kampala,Kibuye, Ndebba, Katwe, Konge, Likuli, Naganda, salama road users, munyonyo, Najjanankumbi, Namasumba, Zzana, Lubowa, Lweza, Kajjasi, Bwebaja, Abaitaababil, Entebbe Airport and makyindye community",
    },
    {
      title: "Mbarara Taxi park 28x7m",
      image:mbarara,
      description:
        "1 face available.Targeting traffic To and From; Rwanda, Kabale, Ntungamo, Rukungiri, Bushenyi, Kasese, Kabale, Ishaka, Mbarara community,  etc.",
    },
    {
      title: "Najjera One Cantilever 14x5m",
      image:najerra,
      description:
        "Landscape Cantilever,2 faces available.Targeting traffic from;Kampala,wandegeya, Bukoto, Kamwokya, Kira, Jinja road Ntinda Kiwatule Najjera,Najjere community, Norther bypass,`etc.  To Najjera two, Kira, Gayaza, Bulindo, Buwate, Mulawa, Namugogo, Kyaliwajjala, kireka etc",
    },
    {
      title: "Kalerwe flyover 3 faced billboard 8x10m",
      image:kalerwe,
      description:
        "portrait Billboard raffic from Kampala, Wandegeya, Makerere, Mulago, Jinja Road, Northern bypass users, Kalerwe Community, etc to  Gayaza, Bugema (SDA) University, Zzirobwe, Rwero, Kabanyoro,  Jinja,  etc. ",
    },
  ];

  return (
    <div className=" w-screen flex justify-center items-center " id="features">
      <div className="w-[75%] flex justify-center items-center flex-col ">
        <div className="flex justify-center items-center flex-col">
          <h1 className="text-[40px] font-medium">Features</h1>
          <p className="text-[1.25rem] text-gray-600">
            Check out some of our products
          </p>
        </div>
        <div className="md:mt-[70px] mt-[60px] md:grid md:grid-cols-4">
          {fetures.map((item, index) => (
            <div key={index} className="w-full">
              <Card title={item.title} description={item.description} image={item.image}/>
            </div>
          ))}
          
        </div>
      </div>
    </div>
  );
};

export default Features;
