import React from "react";
import Slider from "./slider/Slider";
import {motion} from 'framer-motion'



const Testimonials = () => {
  return (
    <div className="w-screen justify-center items-center flex flex-col mt-[20px]" id="testimonials">
      <motion.h1 
      initial={{ y: "-15vw" }}
      whileInView={{ y: 0 }}
      viewport={{ once: false }}
      transition={{ type: "spring", duration: 2, bounce: 0.1 }}
      className="text-[40px]">Clients</motion.h1>
      <div className="w-full p-20">
         <Slider />
      </div>
    </div>
  );
};

export default Testimonials;
