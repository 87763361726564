import React, { useState, useEffect } from "react";
// import logo from '../../images/belle.webp';
import NavLinks from "./NavLinks";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import logo from "../../images/visible-logo.png";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setShow(true);
      } else {
        setShow(false);
      }
    });
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  return (
    <nav
      className={`${
        show
          ? "md:fixed fixed z-20 bg-white drop-shadow-lg duration-500 h-auto "
          : "fixed z-20 "
      }`}
    >
      <div className="flex w-screen items-center font-medium md:justify-around justify-between md:px-[80px] px-[10px]  md:pb-0 h-[65px] pb-[5px]">
        {/*Mobile Nav*/}
        <ul
          className={`
					md:hidden bg-white w-[10100px] ml-[-10px] h-full bottom-0  pl-4  duration-500
					${!open ? "hidden" : "left-[-100%]"}`}
        >
          <NavLinks />
        </ul>

        <div className="md:hidden flex items-center gap-3 pt-[15px]">
          <div className="text-[20px]" onClick={() => setOpen(!open)}>
            {open ? <AiOutlineClose /> : <AiOutlineMenu />}
          </div>
        </div>

        <div className="md:w-auto items-center pt-[10px] flex justify-center w-auto">
          <img src={logo} alt="logo" className="md:cursor-pointer w-[120px]" />
        </div>

        <ul className="md:flex hidden items-center gap-8 font-[Poppins,Arial,Tahoma,Verdana,sans-serif]">
          <NavLinks />
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
