import React from "react";
import image from "../images/profitable.webp";
import { motion } from "framer-motion";

const Home = () => {
  return (
    <div
      className="md:flex md:h-[900px] h-[800px] flex w-full justify-center items-center p-[20px]  md:p-[20px] md:justify-center md:items-center md:gap-9 md:mt-[20px]"
      id="home"
    >
      <motion.div
        initial={{ x: "-45vw" }}
        whileInView={{ x: 0 }}
        viewport={{ once: false }}
        transition={{ type: "spring", duration: 5, bounce: 0.1 }}
        className="md:w-[600px] md:flex-wrap w-full"
      >
        <h1 className="md:text-[3.5rem] md:text-start  text-[4.5rem]">
          Make Your Business <br />
          More Profitable
        </h1>
        <p className="md:text-[1rem] text-[18px] font-serif  md:text-start text-[#6c757d]">
          With a genuine national footprint covering the towns of Kampala,
          Entebbe, Jinja, Mbale, Mbarara, Masaka, Gulu, and Arua.
        </p>
        <div className="mt-[80px] bg-[#4285f4] w-[150px] flex justify-center items-center p-[10px] rounded-[50px] hover:bg-white hover:text-black hover:border-[2px] hover:border-blue-500 border-solid">
          <a href="/" className="text-white hover:text-blue-500">
            Get started
          </a>
        </div>
      </motion.div>

      <motion.div
        initial={{ x: "45vw" }}
        whileInView={{ x: 0 }}
        viewport={{ once: false }}
        transition={{ type: "spring", duration: 2, bounce: 0.1 }}
        className="w-[700px] hidden md:block"
      >
        <img src={image} alt="background" />
      </motion.div>
    </div>
  );
};

export default Home;
