import React, { useState } from "react";
import { links } from "./Links";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { Link } from "react-scroll";

const NavLinks = () => {
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  return (
    <>
      {links.map((link, index) => (
        <div key={index}>
          <div className="px-3 text-left md:cursor-pointer group">
            <h1
              className="py-7 flex hover:text-blue-500 justify-between items-center text-[1rem]"
              onClick={() =>
                heading !== link.name ? setHeading(link.name) : setHeading("")
              }
            >
              <Link to={`${link.id}`} smooth={true} duration={1000}>{link.name}</Link>

            </h1>
          </div>

          {/*Mobile Menu*/}
          <div
            className={`
           	 	${heading === link.name ? "md:hidden" : "hidden"}
           	 	`}
          >
            {/*sub links*/}
            {link.sublinks?.map((mysublinks) => (
              <div>
                <div>
                  <h1
                    onClick={() => {
                      subHeading !== mysublinks.head
                        ? setSubHeading(mysublinks.head)
                        : setSubHeading("");
                    }}
                    className="py-4 pl-7 font-semibold md:pr-0 pr-5 flex items-center justify-between"
                  >
                    {mysublinks.head}
                    <span className="text-xl">
                      {subHeading !== mysublinks.head ? (
                        <AiOutlinePlus />
                      ) : (
                        <AiOutlineMinus />
                      )}
                    </span>
                  </h1>
                  <div
                    className={`${
                      subHeading === mysublinks.head ? "md:hidden" : "hidden"
                    }`}
                  >
                    {mysublinks.sublink.map((slink) => (
                      <li className="py-3 pl-14">
                        <a href={slink.link} className="hover:text-blue-500">
                          {slink.name}
                        </a>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default NavLinks;
