import './App.css';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer'
import Body from './components/Body'

function App() {
  return (
    <div className="flex flex-col flex-1">
      <Navbar />
      <Body />
      <Footer />
    </div>
  );
}

export default App;
