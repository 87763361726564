import React from "react";
import { BiSend } from "react-icons/bi";
import { motion } from "framer-motion";

const Contact = () => {
  return (
    <div
      id="contact"
      className="w-full  flex flex-col justify-center items-center p-[20px] pb-[50px]"
      style={{
        background: "linear-gradient(to right, #4286f4 0%, #42e2f4 100%)",
      }}
    >
      <h1 className="text-[40px] text-white p-2 mt-4">Contact Us</h1>
      <motion.div 
      initial={{ y: "15vw" }}
      whileInView={{ y: 0 }}
      viewport={{ once: false }}
      transition={{ type: "spring", duration: 2, bounce: 0.1 }}
      className="md:w-[35%] w-full bg-white md:flex flex flex-col justify-center items-center md:justify-center md:items-center md:mt-4 md:flex-col md:p-4 p-2">
        <div className="flex flex-col justify-center items-center">
          <div className="w-full md:p-2 pl-4 m-4">
            <h2 className="text-left md:text-[1.5rem] text-[20px]">Contact Form</h2>
          </div>
          <div className="flex p-2 md:w-full w-full justify-between md:justify-between items-center md:items-center ">
            <div className="flex flex-col p-2">
              <label className="font-sans p-2 text-left">First Name</label>
              <input
                placeholder="e.g peter"
                type="text"
                className="border-[2px] border-gray-400 p-2 w-full"
              />
            </div>
            <div className="flex flex-col">
              <label className="p-2 text-left">Last Name</label>
              <input
                placeholder="e.g Ssempijja"
                type="text"
                className="border-[2px] border-gray-400 p-2 w-full"
              />
            </div>
          </div>
          <div className="flex flex-col md:w-full w-full p-2">
            <label className="p-2 text-left">Email</label>
            <input
              placeholder="name@email.com"
              type="text"
              className="border-[2px] border-gray-400 p-2 w-full"
            />
          </div>
          <div className="flex flex-col md:w-full w-full p-2 mt-2">
            <label className="p-2 text-left">Subject</label>
            <input
              placeholder="text"
              type="text"
              className="border-[2px] border-gray-400 p-2"
            />
          </div>
          <div className="flex flex-col md:w-full w-full p-2 mt-2">
            <label className="p-2 text-left">Message</label>
            <input
              placeholder="type your message here"
              type="text"
              className="border-[2px] border-gray-400 p-2 h-[200px] text-start"
            />
          </div>
        </div>
        <div>
          <button className="bg-blue-500 text-white p-4 mt-4 mb-4 rounded-[50px] flex items-center md:w-[200px] justify-between md:text-[20px]">
            Send Message
            <BiSend className="md:text-[30px]" />
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default Contact;
