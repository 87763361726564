import React from "react";
import about from "../images/about.webp";
// import about1 from "../images/about1.png";
import { motion } from "framer-motion";

const About = () => {
  return (
    <div
      className="flex justify-center items-center flex-col mt-[70px] bg-[#f5f4f4] p-[10px]"
      id="about"
    >
      <div className="p-[10px]">
        <h1 className="text-[40px] text-blue-500 font-medium font-sans ">About Us</h1>
      </div>
      <div className="flex  p-[5px] md:justify-center md:items-center mt-[20px] md:gap-32  h-[700px]">
        <motion.div
          initial={{ x: "-45vw" }}
          whileInView={{ x: 0 }}
          viewport={{ once: false }}
          transition={{ type: "spring", duration: 5, bounce: 0.1 }}
          className="md:w-[550px] md:h-[500px] md:flex md:justify-center md:items-center hidden md:block"
        >
          <img src={about} alt="about" />
        </motion.div>

        <motion.div
          initial={{ x: "45vw" }}
          whileInView={{ x: 0 }}
          viewport={{ once: false }}
          transition={{ type: "spring", duration: 2, bounce: 0.1 }}
          className="md:w-[600px] w-full md:flex-wrap md:flex md:justify-between md:items-center"
        >
          <div>
            <h1 className="md:text-[1.75rem] text-[1.7rem] md:text-start text-center">
              Our Mission
            </h1>
            <p className="md:text-[1rem] text-[18px] text-[#6c757d] font-serif md:text-start text-center">
              To provide unmatched quality brand visibility to our clients in
              the most affordable way possible.
            </p>
          </div>

          <div className="mt-[30px]">
            <h1 className="md:text-[1.75rem] md:text-start text-center text-[1.7rem]">
              Our Vision
            </h1>
            <p className="md:text-[1rem] text-[18px] font-serif text-[#6c757d] md:text-start text-center">
              To be the leading and most trust- ed outdoor company in Uganda.
              Our Values Core Values Statement: Sell good merchandise at a
              reason- able profit, treat your custom- ers like human beings, and
              they will always come back for more
            </p>
          </div>

          <div className="mt-[30px]">
            <h1 className="md:text-[1.75rem] md:text-start text-center text-[1.7rem]">
              Our Core Values
            </h1>
            <div className="flex justify-center items-center">
              <ol className="md:text-[1rem] text-[18px] text-[#6c757d] list-disc font-serif">
                <li>Customer commitment</li>
                <li>Quality</li>
                <li>Team work</li>
                <li>Partnership</li>
                <li>Trust</li>
                <li>Integrity</li>
              </ol>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default About;
