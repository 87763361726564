import React from "react";
import About from "./About";
import Contact from "./Contact";
import Features from "./Features";
import Home from "./Home";
import Testimonials from "./Testimonials";
// import investing from '../images/investing.svg'


const Body = () => {
  return (
    <div className="mt-[55px] bg-white  h-full flex justify-center align-center w-screen flex-col">
      <Home />
      <Features />
      <About />
      <Testimonials />
      <Contact />
    </div>
  );
};

export default Body;
